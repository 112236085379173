import $ from 'jquery';

class Modal {
  get defaultSelector() {
    return null;
  }

  constructor(selector = this.defaultSelector) {
    this.$container = $(selector);
    this.$container.find('.modal-close').on('click', this.hide.bind(this));
    this.$container.find('.modal-cover').on('click', this.hide.bind(this));
  }

  show() {
    $('body').addClass('--modal-visible');
    this.$container.addClass('--visible');
  }

  hide(event) {
    // Remove classes only if there is no redirect link
    if ($(event.currentTarget).parent('a').length === 0) {
      $('body').removeClass('--modal-visible');
      this.$container.removeClass('--visible');
    }
  }
}

export default Modal;
