import $ from 'jquery';
import 'jquery.cookie';

$(() => {
  const $buttons = $('.action-buttons');
  const [, chain] = window.location.pathname.split('/');
  const articleIndex = window.currentArticleIndex || 0;
  const keyPrefix = `${chain}-${articleIndex}`;

  function initializeButtons(index, parent) {
    const $parent = $(parent);
    const parentIndex = $buttons.index($parent);
    const key = `${keyPrefix}-${parentIndex}`;
    const variant = localStorage.getItem(key);

    $parent.attr('data-variant', variant);
  }

  function onClick(event) {
    const $parent = $(event.currentTarget);
    const $button = $(event.target);
    const parentIndex = $buttons.index($parent);
    const variant = $button.data('variant');
    const key = `${keyPrefix}-${parentIndex}`;

    localStorage.setItem(key, variant);
    $parent.attr('data-variant', variant);
  }

  $buttons.click(onClick);
  $buttons.each(initializeButtons);
});
