document.addEventListener('DOMContentLoaded', () => {
  const scriptURL =
    'https://script.google.com/macros/s/AKfycbwVunxoDU5av80bFl0Z3bIaL5F01aa6aU-I8LlHykugTYQu4Zo/exec';

  const $questions = document.querySelectorAll('.question-form');

  $questions.forEach($question => {
    const $questionInitButton = $question.querySelector('.question-button-init');
    const $questionSendButton = $question.querySelector('.question-button-send');
    const $questionInput = $question.querySelector('.question-input');
    const $questionForm = $question.querySelector('form');

    $questionInitButton.addEventListener('click', () => {
      $question.classList.remove('question-state-idle');
      $question.classList.add('question-state-active');
    });

    $questionInput.addEventListener('input', () => {
      const isEmpty = $questionInput.value.length === 0;

      if (isEmpty) {
        $questionSendButton.setAttribute('disabled', isEmpty);
      } else {
        $questionSendButton.removeAttribute('disabled');
      }
    });

    $questionForm.addEventListener('submit', event => {
      event.preventDefault();

      $questionSendButton.setAttribute('disabled', true);

      fetch(scriptURL, { method: 'POST', body: new FormData(event.currentTarget) })
        .then(response => {
          $question.classList.add('question-state-done-success');
        })
        .catch(error => {
          $question.classList.add('question-state-done-error');
        })
        .finally(() => {
          $questionSendButton.removeAttribute('disabled');
          $question.classList.remove('question-state-active');
          $question.classList.add('question-state-done');
        });
    });
  });
});
