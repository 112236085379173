import $ from 'jquery';
import AuthModal from './auth-modal';

class AuthErrorModal extends AuthModal {
  get defaultSelector() {
    return '#auth-error-modal';
  }
}

export default AuthErrorModal;
