const AMPLITUDE_CHECK_FREQUENCY = 40; // ms
const AMPLITUDE_CHECK_RETRIES_COUNT = 25;

class Tracker {
  constructor({ apiKey, enabled, debug } = {}) {
    this.apiKey = apiKey;
    this.enabled = !!enabled;
    this.debug = !!debug;
    this.loaded = false;
    this.blocked = false;
    this.queue = [];

    if (this.enabled) {
      this.waitAmplitude();
    }
  }

  /**
   * Core API.
   */

  get api() {
    return window.amplitude.getInstance();
  }

  get inactive() {
    return !this.enabled || this.blocked;
  }

  waitAmplitude() {
    let retriesCount = 0;

    this.api.init(this.apiKey);

    const wait = () => {
      setTimeout(() => {
        if (retriesCount > AMPLITUDE_CHECK_RETRIES_COUNT) return;

        if (typeof window.amplitude === 'undefined') {
          retriesCount += 1;
          wait();
        } else {
          this.initialize();
        }
      }, AMPLITUDE_CHECK_FREQUENCY);
    };

    wait();
  }

  initialize() {
    this.loaded = true;
    this.blocked = !window.amplitude.options;

    setTimeout(() => this.processQueue(), 0);
  }

  processQueue() {
    this.queue.forEach(({ name, data, callback }) => {
      this.track(name, data, callback);
    });
  }

  track(name, data, callback) {
    const xdata = this.extendData(data || {});

    if (this.debug && (this.inactive || this.loaded)) {
      this.logEvent(name, xdata);
    }

    // Do nothing if disabled or blocked.
    if (this.inactive) {
      if (callback) callback();
    } else if (this.loaded) {
      this.api.logEvent(name, xdata, callback);
    } else {
      this.queue.push({ name, data, callback });
    }
  }

  trackLink(event, name, data) {
    event.stopPropagation();
    event.preventDefault();

    this.track(name, data, () => {
      window.location =
        event.currentTarget.href || event.currentTarget.getAttribute('href');
    });
  }

  setUserProperties(props = {}) {
    if (this.debug && (this.inactive || this.loaded)) {
      this.logEvent('setUserProperties', props);
    }

    // Do nothing if not loaded.
    if (this.loaded) {
      this.api.setUserProperties(props);
    }
  }

  extendData(data = {}) {
    return Object.assign(data, { source: window.location.pathname });
  }

  logEvent(name, data) {
    const timestamp = new Date().toLocaleTimeString();

    // eslint-disable-next-line
    console.log(`[Amplitude][${timestamp}]`, name, data);
  }

  /**
   * Helpers.
   */

  pageView() {
    this.track('Page View');
  }

  landingIntroLinkClick(event, data) {
    this.trackLink(event, 'Landing: Intro Link Click', data);
  }

  landingBubbleClick(data) {
    this.track('Landing: Bubble Click', data);
  }

  landingChainWidgetClick(event, data) {
    this.trackLink(event, 'Landing: Chain Widget Click', data);
  }

  articleCompletion(data) {
    this.track('Article: Completion', data);
  }

  articleNavigationLinkClick(event, data) {
    this.trackLink(event, 'Article: Navigation Link Click', data);
  }

  articleAnswerClick(data) {
    this.track('Article: Answer Click', data);
  }

  adBannerView(data) {
    this.track('Ad Banner: View', data);
  }

  adBannerClick(event, data) {
    this.trackLink(event, 'Ad Banner: Click', data);
  }

  subscriptionBannerView() {
    this.track('Subscription: View');
  }

  subscriptionBannerSubmission() {
    this.track('Subscription: Submission');
  }
}

export default new Tracker(window.TRACKER_CONFIG);
