import '../styles/main.scss';

import $ from 'jquery';
import likely from 'ilyabirman-likely';

import { AuthModal, AuthErrorModal, AuthSubscribeModal } from './modals';
import './events';
import './widget';
import './voting';
import './calc';
import './question';

const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

$(() => {
  likely.initiate();

  const scriptURL =
    'https://script.google.com/macros/s/AKfycbwVunxoDU5av80bFl0Z3bIaL5F01aa6aU-I8LlHykugTYQu4Zo/exec';
  const forms = $('.submit-to-google-sheet');

  if (forms.length) {
    forms.submit(e => {
      e.preventDefault();
      const formContainer = $(e.currentTarget).closest('.form-container');

      formContainer.addClass('sending');

      fetch(scriptURL, { method: 'POST', body: new FormData(e.currentTarget) })
        .then(response =>
          formContainer.addClass('success').removeClass('sending')
        )
        .catch(error => formContainer.addClass('error').removeClass('sending'));
    });
  }

  const $emailSubscriptionForm = $('.email-subscription-form');

  if ($emailSubscriptionForm) {
    $emailSubscriptionForm.on('submit', event => {
      event.preventDefault();

      const $form = $(event.currentTarget);
      const $formContainer = $form.closest('.form-container');
      const $formWrapper = $form.closest('.form-wrapper');
      const email = $form.find('input[type="email"]').val() || '';

      if (EMAIL_REGEXP.test(email)) {
        $formContainer.addClass('sending');

        $.ajax({
          url: $form.attr('action'),
          method: $form.attr('method'),
          data: $form.serialize(),
        })
          .done(() => {
            $formContainer.addClass('success').removeClass('sending');
          })
          .catch(error => {
            $formContainer.addClass('error').removeClass('sending');
            console.log(error);
          });
      } else {
        $formWrapper
          .addClass('validation-error')
          .one(
            'animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd',
            () => $formWrapper.removeClass('validation-error')
          );
      }
    });

    $('.js-scroll-link').click(function(e) {
      e.preventDefault();

      var linkOffset = -20;
      if ($($.attr(this, 'href')).data('scroll-link-offset')) {
        linkOffset += $($.attr(this, 'href')).data('scroll-link-offset');
      }

      $('html, body').animate(
        {
          scrollTop: $($.attr(this, 'href')).offset().top + linkOffset,
        },
        500
      );
    });
  }

  const $headerSubmenuDrop = $('.page-header__submenu-drop');
  const $headerSubmenu = $('.page-header__submenu');

  $headerSubmenu.on('click', () => $headerSubmenuDrop.toggle());
  $(document).mouseup(e => {
    if (
      !$headerSubmenuDrop.is(e.target) &&
      $headerSubmenuDrop.has(e.target).length === 0 &&
      !$headerSubmenu.is(e.target)
    ) {
      $headerSubmenuDrop.hide();
    }
  });

  /*
  ---------------------------
  Winvestor banner at the top
  ---------------------------
  */
  const $winvestorTopBanner = $('.winvestor-top-banner');
  if (localStorage.getItem('winvestor-banner-hidden'))
    localStorage.removeItem('winvestor-banner-hidden');

  if ($winvestorTopBanner) {
    const bannerVisible = localStorage.getItem('winvestor-banner-hidden');

    if (!bannerVisible) {
      $winvestorTopBanner.addClass('visible');
    }

    const $winvestorTopBannerButton = $winvestorTopBanner.find(
      '.winvestor-top-banner-close button'
    );

    $winvestorTopBannerButton.on('click', event => {
      event.stopPropagation();

      $winvestorTopBanner.removeClass('visible');
      localStorage.setItem('winvestor-banner-hidden', true);
    });
  }

  /*
  -------------------
  MODALS
  -------------------
  */
  const authModal = new AuthModal();
  const authErrorModal = new AuthErrorModal();
  const authSubscribeModal = new AuthSubscribeModal();

  const $authHeaderButtons = $('header .auth-button');
  const $articleLinks = $('a.article-promo.--restricted');

  if ($authHeaderButtons) {
    $authHeaderButtons.on('click', event => {
      event.stopPropagation();
      authModal.show();
    });
  }

  if ($articleLinks) {
    $articleLinks.on('click', event => {
      event.stopPropagation();
      event.preventDefault();
      authSubscribeModal.show(event.currentTarget.dataset);
    });
  }

  authModal.enableForm();
  authErrorModal.enableForm();
  authSubscribeModal.enableForm();

  /*
  -------------------------------
  Habits — types of people naming
  -------------------------------
  */

  let currentTypeName = [];
  let typeName = [];

  let namesMorphs = [
    [
      [
        'импульсивный',
        'импульсивного',
        'импульсивному',
        'импульсивный',
        'импульсивным',
        'импульсивном',
        'импульсивные',
        'импульсивных',
        'импульсивным',
        'импульсивные',
        'импульсивными',
        'импульсивных',
      ],

      [
        'нормальный',
        'нормального',
        'нормальному',
        'нормальный',
        'нормальным',
        'нормальном',
        'нормальные',
        'нормальных',
        'нормальным',
        'нормальные',
        'нормальными',
        'нормальных',
      ],

      [
        'неорганизованный',
        'неорганизованного',
        'неорганизованному',
        'неорганизованный',
        'неорганизованным',
        'неорганизованном',
        'неорганизованные',
        'неорганизованных',
        'неорганизованным',
        'неорганизованные',
        'неорганизованными',
        'неорганизованных',
      ],

      [
        'оптимистичный',
        'оптимистичного',
        'оптимистичного',
        'оптимистичный',
        'оптимистичным',
        'оптимистичным',
        'оптимистичные',
        'оптимистичных',
        'оптимистичным',
        'оптимистичные',
        'оптимистичными',
        'оптимистичных',
      ],
    ],

    [
      [
        'скучный',
        'скучного',
        'скучному',
        'скучный',
        'скучным',
        'скучном',
        'скучные',
        'скучных',
        'скучным',
        'скучные',
        'скучными',
        'скучных',
      ],

      [
        'осознанный',
        'осознанного',
        'осознанному',
        'осознанный',
        'осознанным',
        'осознанном',
        'осознанные',
        'осознанных',
        'осознанным',
        'осознанные',
        'осознанными',
        'осознанных',
      ],

      [
        'организованный',
        'организованного',
        'организованному',
        'организованный',
        'организованным',
        'организованном',
        'организованные',
        'организованных',
        'организованным',
        'организованные',
        'организованными',
        'организованных',
      ],

      [
        'нормальный',
        'нормального',
        'нормальному',
        'нормальный',
        'нормальным',
        'нормальном',
        'нормальные',
        'нормальных',
        'нормальным',
        'нормальные',
        'нормальными',
        'нормальных',
      ],
    ],
  ];

  for (let i = 1; i < 3; i++) {
    typeName[i] = 0;

    let lsValue = localStorage.getItem(`type${i}-name`);

    if (lsValue && !isNaN(parseInt(lsValue))) {
      typeName[i] = lsValue;
    } else if ($(`.type-toggle`).length) {
      typeName[i] = $(`.type-toggle[name=type${i}toggle] input:checked`).val();
    }
    setTypeName(i, typeName[i]);
  }

  $('.type-toggle').each(function() {
    let type = $(this).data('type');
    $(this)
      .find('input:radio')
      .change(function() {
        setTypeName(type, $(this).val());
      });
  });

  function setTypeName(type, newTypeName) {
    localStorage.setItem(`type${type}-name`, newTypeName);

    $(`.type-toggle[name=type${type}toggle] input:radio`)
      .filter(`[value=${newTypeName}]`)
      .prop('checked', true);

    $(`.type${type}`).each(function() {
      let morph = $(this).data('morph');
      $(this).text(namesMorphs[type - 1][newTypeName - 0][morph]);
    });

    currentTypeName[type] = newTypeName[type];
  }

  /*
  -------------------------------
  ARTICLES -- Костыль (по хорошему сделать через севрер но делать много нужно , решили сделать побыстрому)
  -------------------------------
 */

  const limit = 6;

  $('.articles-news').each((index, item) => {
    if (item.childElementCount > limit) {
      for (let i = limit; i <= item.childElementCount - 1; i++) {
        item.childNodes[i].style.display = 'none';
      }
    }
  });

  $('.show-articles').on('click', element => {
    let block = $(
      `.wds__grid.category-${element.target.classList[1].split('__')[1]}`
    );

    if (block[0]) {
      let page = element.target.getAttribute('data-page');
      let new_limit = limit * parseInt(page);

      for (let i = new_limit - limit; i <= new_limit - 1; i++) {
        if (block[0].childNodes[i]) {
          block[0].childNodes[i].style.display = 'block';
        }
      }
      element.target.setAttribute('data-page', parseInt(page) + 1);

      if (new_limit >= block[0].childElementCount) {
        element.target.parentElement.style.display = 'none';
      }
    }
  });
});
