import $ from 'jquery';
import tracker from './utils/tracker';

const toLowerCaseText = text => (text || '').toLowerCase().trim();

const handleViewOnScroll = ($el, callback, threshold = 0) => {
  const onWindowScroll = () => {
    const scrollTop = $(window).scrollTop();
    const scrollBottom = scrollTop + $(window).height();

    if (threshold > scrollTop && threshold < scrollBottom) {
      callback();
      $(window).off('scroll', onWindowScroll);
    }
  };

  $(window).scroll(onWindowScroll);
};

// Track a view as soon as possible.
tracker.pageView();

$(() => {
  const $introLinks = $('.promobox .intro-link');
  const $bubbles = $('.guesswork .guess-bubble');

  // TODO: оставить только один вариант, когда появится новая главная
  let $chains = $('.promobox .publication-chain');
  if ($chains.length === 0) {
    $chains = $('.promobox .chain-widget');
  }

  const $adBanner = $('a.banner');
  const $subscription = $('.form-container.subscribe');
  const $articleNavigationLink = $('.article .navigation_link');
  const $articleWidgetLinks = $('.article .widget-links');
  const $articleActionButtons = $('.article .action-buttons');

  if ($introLinks.length) {
    $introLinks.click(event => {
      const $link = $(event.currentTarget);
      const text = toLowerCaseText($link.text());
      const url = $link.get(0).pathname;

      tracker.landingIntroLinkClick(event, { text, url });
    });
  }

  if ($bubbles.length) {
    $bubbles.click(event => {
      const $bubble = $(event.currentTarget);
      const text = toLowerCaseText($bubble.text());
      const step = $bubble
        .closest('.guesswork__step')
        .attr('id')
        .slice(-1);

      tracker.landingBubbleClick({ text, step });
    });
  }

  // if ($chains.length) {
  //   $chains.each((index, chain) => {
  //     const $chain = $(chain);
  //     // TODO: убрать ненужные классы, когда будет новая главная
  //     const $links = $chain.find(
  //       'a.publication-block, .publication-block a, a.publication-name-row'
  //     );
  //     const $title = $chain.find('.publication-chain__title');

  //     $chain.add($links).click(event => {
  //       const $link = $(event.currentTarget);
  //       // TODO: тоже оставить только один вариант
  //       const isChainLink =
  //         $link.hasClass('publication-chain') || $link.hasClass('chain-widget');

  //       const recommended = $chain.hasClass('recommended');
  //       const progress = $links.filter('.done').length;
  //       const title = toLowerCaseText($title.text());
  //       const text = isChainLink ? '' : toLowerCaseText($link.text());
  //       const url = isChainLink ? $link.attr('href') : $link.get(0).pathname;

  //       tracker.landingChainWidgetClick(event, {
  //         recommended,
  //         progress,
  //         title,
  //         text,
  //         url,
  //       });
  //     });
  //   });
  // }

  if ($adBanner.length) {
    $adBanner.click(event => {
      const title = toLowerCaseText($adBanner.find('h2').text());
      const url = $adBanner.attr('href');

      tracker.adBannerClick(event, { title, url });
    });

    handleViewOnScroll(
      $adBanner,
      () => {
        const title = toLowerCaseText($adBanner.find('h2').text());
        const url = $adBanner.attr('href');

        tracker.adBannerView({ title, url });
      },
      $adBanner.offset().top + $adBanner.outerHeight(true) / 2
    );
  }

  if ($subscription.length) {
    const $form = $subscription.find('form');

    $form.on('submit', () => {
      tracker.subscriptionBannerSubmission();
    });

    handleViewOnScroll(
      $subscription,
      () => tracker.subscriptionBannerView(),
      $subscription.offset().top + $subscription.outerHeight(true) / 2
    );
  }

  if ($articleWidgetLinks.length) {
    const start = new Date();

    handleViewOnScroll(
      $articleWidgetLinks,
      () => {
        const end = new Date();
        const [, chain, article] = window.location.pathname.split('/');
        const time = (end - start) / 1000;

        tracker.articleCompletion({ chain, article, time });
      },
      $articleWidgetLinks.offset().top
    );
  }

  if ($articleNavigationLink.length) {
    const $links = $articleNavigationLink.find('.widget-link a');
    const $title = $articleNavigationLink.find('.footer-read-next h2');
    const title = $title.text().replace('→', '');

    $articleNavigationLink.add($links).click(event => {
      const $link = $(event.currentTarget);

      const [, chain, article] = window.location.pathname.split('/');
      const direct = !$link.hasClass('navigation_link');
      const text = toLowerCaseText(direct ? $link.text() : title);
      const url = direct ? $link.get(0).pathname : $link.attr('href');
      const next = url === $articleNavigationLink.attr('href');

      tracker.articleNavigationLinkClick(event, {
        chain,
        article,
        direct,
        next,
        text,
        url,
      });
    });
  }

  if ($articleActionButtons.length) {
    $articleActionButtons.click(event => {
      const $parent = $(event.currentTarget);
      const $button = $(event.target);
      const $title = $parent.closest('.card').find('h3');

      const [, chain, article] = window.location.pathname.split('/');
      const index = $articleActionButtons.index($parent);
      const title = toLowerCaseText($title.text());
      const text = toLowerCaseText($button.text());
      const variant = $button.data('variant');

      tracker.articleAnswerClick({
        chain,
        article,
        index,
        title,
        text,
        variant,
      });
    });
  }
});
