import $ from 'jquery';
import 'jquery.cookie';
import tracker from './utils/tracker';

const tryParseJson = str => {
  let obj = {};

  try {
    obj = JSON.parse(str);
  } catch (error) {} // eslint-disable-line

  return typeof obj === 'object' ? obj : {};
};

const snakeToCamel = str =>
  str.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );

$(() => {
  const $threshold = $('.form-container.subscribe');

  if ($threshold.length > 0) {
    const threshold = $threshold.offset().top;
    const [, chain] = window.location.pathname.split('/');
    const widgets = tryParseJson($.cookie('widgets'));
    const nextArticleIndex = widgets[chain] || 0;
    const currentArticleIndex = window.currentArticleIndex || 0;

    const onWindowScroll = () => {
      const scrollTop = $(window).scrollTop();
      const scrollBottom = scrollTop + $(window).height();

      if (threshold > scrollTop && threshold < scrollBottom) {
        widgets[chain] += 1;

        $.cookie('widgets', JSON.stringify(widgets), {
          expires: 9000, // over 9k
          path: '/',
        });

        $(window).off('scroll', onWindowScroll);

        // Track progress in Amplitude user properties.
        const progressProp = `${snakeToCamel(chain)}ChainProgress`;
        tracker.setUserProperties({ [progressProp]: widgets[chain] });
      }
    };

    if (currentArticleIndex === nextArticleIndex) {
      widgets[chain] = widgets[chain] || 0;
      $(window).on('scroll', onWindowScroll);
    }
  }

  const $widgetHeaderNav = $('.header-nav');

  if ($widgetHeaderNav) {
    const $widgetLinksContent = $widgetHeaderNav.find('.widget-link');

    $widgetHeaderNav.on('click', () => {
      $widgetHeaderNav.toggleClass('--visible');
    });

    $widgetLinksContent.on('click', event => {
      event.stopPropagation();
    });

    $(document).on('click', event => {
      if (
        !$widgetHeaderNav.is(event.target) &&
        $widgetHeaderNav.has(event.target).length === 0
      ) {
        $widgetHeaderNav.removeClass('--visible');
      }
    });
  }
});

$(() => {
  // убираю ссылку с виджета наверху страницы
  $('.navigation_no-link').click(function() {
    return false;
  });

  // if ($.cookie('widgets')) {
  //   const publicationActual = JSON.parse($.cookie('widgets'));
  //   $('.publication-chain__progress').hide();

  //   $.each(publicationActual, (index, value) => {
  //     const publicationAll = $('.' + index)
  //       .children('.publication-chain__blocks')
  //       .children('.publication-block').length;

  //     $('.' + index)
  //       .find('.publication-chain__progress')
  //       .show()
  //       .children('span')
  //       .text(Math.round((value * 100) / publicationAll) + '%');
  //   });
  // } else {
  //   $('.publication-chain__progress').hide();
  // }

  // if ($.cookie('widgets')) {
  //   const publications = JSON.parse($.cookie('widgets'));

  //   $.each(publications, (index, value) => {
  //     const currentWidget = $('.' + index);
  //     const progress = currentWidget
  //       .find('.publication-chain__progress > span')
  //       .html();
  //     const firstPublication = $('#' + index + '-links')
  //       .find('a:first-child')
  //       .attr('href');

  //     if (progress == '100%') {
  //       currentWidget.attr('href', firstPublication);
  //     }

  //     if (progress == '0%') {
  //       currentWidget.find('.publication-chain__progress').hide();
  //     }
  //   });
  // }

  // ставлю ссылку заголовку статьи внизу страницы
  const nextLocation =
    $('.widget-links')
      .find('span')
      .closest('.widget-link')
      .next('.widget-link')
      .find('a')
      .attr('href') ||
    $('.widget-links')
      .find('a')
      .first()
      .attr('href');

  $('.navigation_link')
    .attr('href', nextLocation)
    .children('.publication-chain')
    .attr('href', nextLocation);

  // несанкционированный скриптинг от Жени А.
  $('.money-bar').each(function() {
    var barValue = $(this).data('value');
    $(this).css('width', barValue / 38 + '%'); // волшебный масштаб
  });
});
