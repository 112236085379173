import $ from 'jquery';
import AuthModal from './auth-modal';

class AuthSubscribeModal extends AuthModal {
  get defaultSelector() {
    return '#auth-subscribe-modal';
  }

  show({ title }) {
    this.$container.find('.auth-modal-main>h3').text(title);

    super.show();
  }
}

export default AuthSubscribeModal;
