import $ from 'jquery';

document.addEventListener('DOMContentLoaded', () => {
  const $calc = document.querySelector('.free-money-calc');
  const $sliders = document.querySelectorAll('.slider');
  const values = {};
  const maximums = {};
  const ratios = {};
  const defaultRatios = {};

  function initializeRatios() {
    Object.assign(ratios, {
      expenses: values.expenses / values.amount,
      free: values.free / values.amount,

      home: values.home / values.expenses,
      food: values.food / values.expenses,
      transport: values.transport / values.expenses,
      fun: values.fun / values.expenses,

      impulse: values.impulse / values.free,
      savings: values.savings / values.free,
    });

    Object.assign(defaultRatios, {
      expenses: values.expenses / values.amount,
      free: values.free / values.amount,
      home: {
        food: values.food / (values.food + values.transport + values.fun),
        transport:
          values.transport / (values.food + values.transport + values.fun),
        fun: values.fun / (values.food + values.transport + values.fun),
      },
      food: {
        home: values.home / (values.home + values.transport + values.fun),
        transport:
          values.transport / (values.home + values.transport + values.fun),
        fun: values.fun / (values.home + values.transport + values.fun),
      },
      transport: {
        home: values.home / (values.home + values.food + values.fun),
        food: values.food / (values.home + values.food + values.fun),
        fun: values.fun / (values.home + values.food + values.fun),
      },
      fun: {
        home: values.home / (values.home + values.food + values.transport),
        food: values.food / (values.home + values.food + values.transport),
        transport:
          values.transport / (values.home + values.food + values.transport),
      },
    });
  }

  function updateAmountDependencies(newExpences, newFree) {
    const { amount } = values;

    const expenses =
      typeof newExpences === 'undefined'
        ? +amount * +ratios.expenses
        : +newExpences;

    const free =
      typeof newFree === 'undefined' ? +amount * +ratios.free : +newFree;

    Object.assign(values, {
      expenses,
      free,
    });

    Object.assign(maximums, {
      expenses: amount,
      free: amount,
    });
    if (values.amount > 0) {
      Object.assign(ratios, {
        expenses: expenses / amount,
        free: free / amount,
      });
    } else if (values.amount === 0) {
      Object.assign(ratios, {
        expenses: defaultRatios.expenses,
        free: defaultRatios.free,
      });
    }
  }

  function updateExpencesDependencies() {
    const { expenses } = values;

    Object.assign(maximums, {
      home: expenses,
      food: expenses,
      transport: expenses,
      fun: expenses,
    });

    Object.assign(values, {
      home: expenses * ratios.home,
      food: expenses * ratios.food,
      transport: expenses * ratios.transport,
      fun: expenses * ratios.fun,
    });
  }

  function updateFreeDependencies() {
    const { free } = values;

    Object.assign(maximums, {
      impulse: free,
      savings: free,
    });

    Object.assign(values, {
      impulse: free * ratios.impulse,
      savings: free * ratios.savings,
    });
  }
  function updateExpensesDependencies(key, value) {
    values[key] = value;

    const allKeys = ['home', 'food', 'transport', 'fun'];
    const depsKeys = allKeys.filter(depsKey => depsKey !== key);
    const depsSum = depsKeys.reduce((sum, depsKey) => sum + values[depsKey], 0);

    values.free = values.amount - value - depsSum;
    values.impulse = values.free * ratios.impulse;
    values.savings = values.free * ratios.savings;
    values.expenses = value + depsSum;

    maximums[key] = value + values.free;
    maximums.impulse = values.free;
    maximums.savings = values.free;

    depsKeys.forEach(depsKey => {
      maximums[depsKey] = values[depsKey] + values.free;
    });
  }
  function updateAmount(value) {
    values.amount = value;

    updateAmountDependencies();
    updateExpencesDependencies();
    updateFreeDependencies();

    $calc.classList.toggle('special-case', value >= 1000000);
  }

  function updateExpenses(value) {
    const expenses = value;

    updateAmountDependencies(value, values.amount - expenses);
    updateExpencesDependencies();
    updateFreeDependencies();
  }

  function updateFree(value) {
    const free = value;

    updateAmountDependencies(values.amount - free, value);
    updateExpencesDependencies();
    updateFreeDependencies();
  }

  function updateImpulse(value) {
    Object.assign(values, {
      impulse: value,
      savings: values.free - value,
    });
  }

  function updateSavings(value) {
    Object.assign(values, {
      impulse: values.free - value,
      savings: value,
    });
  }

  function updateHome(value) {
    updateExpensesDependencies('home', value);
  }

  function updateFood(value) {
    updateExpensesDependencies('food', value);
  }

  function updateTransport(value) {
    updateExpensesDependencies('transport', value);
  }

  function updateFun(value) {
    updateExpensesDependencies('fun', value);
  }

  function formatValue(value) {
    if (value > 9999) {
      return `${(+value).toLocaleString('ru')} ₽`;
    }
    return `${+value} ₽`;
  }

  function updateSlider($slider) {
    const { key } = $slider.dataset;
    const $input = $slider.querySelector('input');
    const value = Math.round(values[key]);
    const max = Math.round(maximums[key]);

    Object.assign($slider.dataset, {
      value,
      max,
      text: formatValue(value),
    });

    $input.value = value;
    $input.max = max;
  }

  function updateSliders() {
    $sliders.forEach(updateSlider);
  }

  function updateCalc(key, value) {
    switch (key) {
      case 'amount': {
        updateAmount(value);
        break;
      }
      case 'expenses': {
        updateExpenses(value);
        break;
      }
      case 'free': {
        updateFree(value);
        break;
      }
      case 'impulse': {
        updateImpulse(value);
        break;
      }
      case 'savings': {
        updateSavings(value);
        break;
      }
      case 'home': {
        updateHome(value);
        break;
      }
      case 'food': {
        updateFood(value);
        break;
      }
      case 'transport': {
        updateTransport(value);
        break;
      }
      case 'fun': {
        updateFun(value);
        break;
      }
      default:
        break;
    }

    updateSliders();
  }

  $sliders.forEach($slider => {
    const $input = document.createElement('input');
    const { min, max, value, step, key } = $slider.dataset;

    $input.min = min;
    $input.max = max;
    $input.step = step;
    $input.value = value;
    $input.type = 'range';

    Object.assign($slider.dataset, {
      text: formatValue(value),
    });

    values[key] = +value;
    maximums[key] = +max;

    $input.addEventListener('input', () => {
      updateCalc(key, +$input.value);
    });

    $slider.appendChild($input);

    $(window).on('scroll.calc', () => {
      const scrollTop = $(window).scrollTop();
      const scrollBottom = scrollTop + $(window).height();
      const freeMoneyCalc = $('.free-money-calc');
      const threshold =
        freeMoneyCalc.offset().top + freeMoneyCalc.outerHeight() / 2;
      if (threshold > scrollTop && threshold < scrollBottom) {
        (() => {
          let start = null;
          let progress = null;
          const endPos = $slider.dataset.value;
          const duration = 1000;
          $input.value = 0;
          $slider.dataset.text = 0;
          $(window).off('scroll.calc');
          function animationStep(timestamp) {
            if (!start) {
              start = timestamp;
              $input.step = 1;
            }
            progress = timestamp - start;
            $input.value = (progress / duration) * endPos;
            $slider.dataset.text = formatValue($input.value);
            if (progress < duration) {
              window.requestAnimationFrame(animationStep);
            } else {
              $slider.dataset.text = formatValue($slider.dataset.value);
              $input.value = $slider.dataset.value;
              $input.step = step;
            }
          }
          window.requestAnimationFrame(animationStep);
        })();
      }
    });
  });

  initializeRatios();
});
