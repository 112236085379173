import Modal from './modal';

const EMAIL_REGEXP = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

class AuthModal extends Modal {
  get defaultSelector() {
    return '#auth-modal';
  }

  enableForm() {
    const content = this.$container.find('.auth-modal');
    const form = this.$container.find('.email-link-form');

    content.on('click', event => event.stopPropagation());

    form.on('submit', async event => {
      event.preventDefault();

      const email = event.target.email.value;
      const optIn = event.target.optIn.value;

      if (EMAIL_REGEXP.test(email)) {
        form.addClass('sending').removeClass('--no-message');
        content.removeClass(['--success', '--error', '--no-message']);

        const { action, method } = event.target;
        const response = await fetch(action, {
          method,
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, optIn }),
        });

        form.removeClass('sending');

        if (response.ok) content.addClass('--success');
        else content.addClass('--error');
      } else {
        form
          .addClass('validation-error')
          .one(
            'animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd',
            () => form.removeClass('validation-error')
          );
      }
    });
  }
}

export default AuthModal;
